import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

const Terms = () => {
  return (
   <>
   <Navbar/>
    <div className="container prv-term-cookie privacy-container px-4 py-5 float-none" id="featured-3" bis_skin_checked="1"> <h2 className="pb-2 border-bottom">Terms &amp; Conditions</h2> <div className="row g-4 py-5 row-cols-1 row-cols-lg-1" bis_skin_checked="1"> <p>
        INTRODUCTION<br/>nzgameszone.com is only intended
                    for people aged 18 and over. No real money or anything valuable can
                    be won in these games. Practice or success in gaming
                    does not mean future success in real money gaming. If you are under
                    the age of 18, you are not authorized to access or otherwise use
                    the Services.<br/>By using the Services or Games, you confirm
                    that you are at least 18 years old. We reserve the right to
                    check the age at any time. We also reserve the right to
                    terminate the Services in whole or in part, or to terminate or
                    deny access to the Services by any individual at any time at our
                    discretion.
        </p> <p>
        COMMUNICATION<br/>You expressly consent to receiving
                    communications from time to time by SMS, push notification and /
                    or email, including promotional communications, as part of the
                    Services. You can stop receiving commercial communications by
                    e-mail by clicking on the unsubscribe links contained in such
                    e-mails or by sending your unsubscribe request along with your
                    mobile phone number by e-mail to [e-mail protected] or by push
                    notifications Deactivate your mobile device. You can stop
                    receiving promotional messages by SMS by responding to the
                    promotional text with “Stop”. You are not allowed to deactivate
                    service-related emails.<br/>You must have an internet-enabled
                    mobile phone or device using either the iOS or Android operating
                    system to access the company’s mobile platform. Any content you
                    receive from the Services may be subject to normal
                    transportation charges and taxes. The company is not responsible
                    for any surcharges you may incur from your mobile phone or
                    Internet service provider for your use of the services.<br/>Use
                    of the Services is subject to the terms of our Privacy Policy,
                    which is hereby incorporated into this Agreement and becomes an
                    integral part of this Agreement. Please read our privacy policy
                    carefully. By using the services, you confirm that you have read
                    and agree to the terms of the privacy policy.<br/>We reserve
                    the right, and you authorize us, to use information about your
                    use of the Services, account registration and other personal
                    information you have provided in accordance with our Privacy
                    Policy. You further acknowledge and agree that all disputes
                    relating to the Privacy Policy, including any security or
                    privacy breach, are subject to the liability and dispute
                    settlement provisions contained in this Agreement.
        </p> <p>
        CODE OF CONDUCT<br/>If necessary in the Services (e.g. in
                    Message Boards) you will be asked to publish your own content
                    (“User Content”). You understand that all User Content, whether
                    you have posted it publicly on a forum, posted it as part of
                    your profile, or privately transmitted it to another Service
                    User or to us, is your sole responsibility. Although the
                    Services are designed as a safe place for sharing such User
                    Content, the Company cannot guarantee that other users will not
                    abuse the User Content you have shared. If you have User Content
                    that you want to keep confidential and / or that you do not want
                    others to use (including, but not limited to, photos, personal
                    information, name, home address, phone number, etc.), do not
                    post it on the Services. Under no circumstances will the Company
                    be responsible in any way for any User Content, including but
                    not limited to errors or omissions in User Content, or for any
                    loss or damage of any kind arising from the use of any User
                    Content posted through the Services, via email Mail sent or
                    otherwise transmitted. By posting User Content on the Services,
                    you hereby grant the Company a royalty-free, fully paid,
                    perpetual, irrevocable, non-exclusive and fully sublicensable
                    right as well as a license to use, reproduce, modify, adapt,
                    publish and translate, combine with other works, create,
                    distribute, perform, edit and display derivative works from such
                    User Content (in whole or in part) worldwide and / or
                    incorporate them into other works in any form, media or
                    technology now known or later developed. You expressly waive
                    “moral rights” to and for the user content. The foregoing grant
                    includes, without limitation, all copyrights and other
                    intellectual property rights in and for your User Content. You
                    represent and warrant that: you own the User Content you post on
                    or through the Services or otherwise have the right to grant the
                    license set out in this section; and the publication of your
                    User Content on or via the Services does not violate any
                    person’s data protection, publicity, copyright, contractual or
                    other rights. You agree to pay all royalties, fees, and other
                    monies owed to any person as a result of any User Content that
                    you have posted on or through the Services. You acknowledge and
                    agree that the Company may retain User Content and disclose or
                    use User Content in its sole discretion. You understand that the
                    technical processing and transmission of the Services, including
                    your User Content, may include: transmissions over various
                    networks; and changes to adapt to the technical requirements
                    when connecting networks or devices. You agree to each of the
                    terms in this Agreement and further agree that each of these
                    terms shall apply forever and generally with respect to the
                    Company worldwide. Posting User Content on or via the Services,
                    including ideas or disclosures of opinions, is voluntary on your
                    part. Your posting of User Content does not create a
                    confidential or additional contractual relationship or implies
                    our review or subsequent use of your User Content. The Company
                    is not responsible for the disclosure of any User Content,
                    including any opinions or suggestions, that you submit to or
                    through the Services.<br/>You agree that you will not use the
                    Services to:<br/>(a) Upload, post, email, or otherwise transmit
                    User Content that is illegal, harmful, threatening, abusive,
                    harassing, defamatory, vulgar, obscene, pornographic,
                    slanderous, violating another’s privacy, hateful or racist, is
                    ethnically or otherwise illegal or objectionable (including,
                    without limitation, nudity and depiction of drug use);<br/>(b)
                    cause harm to minors in any way;<br/>(c) impersonate any person
                    or organization, including but not limited to a representative
                    of the Company, or misrepresent or otherwise misrepresent your
                    affiliation with any person or organization;<br/>(d) forge
                    headers or otherwise manipulate identifiers in order to disguise
                    the origin of user content transmitted through the Services;<br/>(e) Upload, publish, email, or otherwise transmit User Content
                    that you are not authorized to transmit under any law or due to
                    contractual or fiduciary relationships (e.g. inside information,
                    proprietary and confidential information provided under learned
                    or disclosed from employment relationships) or under
                    non-disclosure agreements);<br/>(f) upload, post, email, or
                    otherwise transmit User Content that infringes any patents,
                    trademarks, trade secrets, copyrights or other proprietary
                    rights (“Rights”) of any party;<br/>(g) upload, post, email or
                    otherwise transmit unsolicited or unauthorized advertising,
                    promotional materials, junk mail, spam mail, chain letters,
                    pyramid schemes or other forms of advertising, except in these
                    areas are intended for this purpose;<br/>(h) upload, post,
                    email, or otherwise transmit any material that contains software
                    viruses or other computer code, files or programs designed to
                    interrupt, emulate, destroy or limit the functionality of any
                    computer software or hardware or telecommunications equipment;<br/>(i) disrupt the normal flow of dialogue, “scroll” a screen
                    faster than other users of the Services can type, or otherwise
                    act in a way that adversely affects other users’ ability to
                    communicate in real time;<br/>(j) to disrupt or disrupt the
                    services or servers or networks connected to the Services or to
                    violate the requirements, procedures, guidelines or regulations
                    of networks connected to the Services;<br/>(k) “stalk” or
                    otherwise harass another;<br/>(l) collect or store personal
                    information or data about other service users or disclose the
                    personal information or data of another user to third parties;<br/>(m) request personal information or information from anyone
                    under the age of 18; and or<br/>(n) “bot”, “hack”, “crack” or
                    otherwise attempt to circumvent access control, copyright, or
                    license enforcement mechanisms associated with or related to the
                    Services.<br/>You understand that by using the Services you may
                    be exposed to User Content created by others that is
                    objectionable, indecent or indecent. The company does not
                    endorse or control what is posted as User Content. Company does
                    not review user content prior to posting, and does not reflect
                    Company opinions or policies. The Company makes no
                    representations or warranties, express or implied, regarding any
                    User Content or the accuracy or reliability of any User Content
                    or any other material or information that you may access through
                    the Services. Company is not responsible for monitoring the
                    Services for inappropriate input or behavior, but reserves the
                    right to do so. If at any point in time, in its sole discretion,
                    Company elects to monitor the Services, Company does not accept
                    responsibility for User Content, has no obligation to change or
                    remove inappropriate User Content, and is not responsible for
                    the behavior of users of the Services submitting such User
                    Content.<br/>Notwithstanding the foregoing, Company and its
                    agents have the right, at Company’s sole discretion, to remove
                    User Content at any time without notice and for any reason,
                    including, but not limited to, content that violates this
                    Agreement or is otherwise objectionable . You agree that you
                    must evaluate and assume all risks associated with the use of
                    User Content, including any reliance on the accuracy,
                    completeness, or usefulness of such User Content. The company
                    can store user content indefinitely. However, the company is
                    under no obligation to save user content or make it available to
                    you in the future.<br/>You are solely responsible for your
                    interactions with other service users. The company reserves the
                    right, but is under no obligation, to monitor disputes between
                    you and other service users. Company reserves the right to
                    terminate access to your services if Company determines in its
                    sole discretion that it is prudent to do so.<br/>You can submit
                    reviews or other feedback using forms on the Services. Any
                    comments, suggestions, or feedback relating to the Services
                    (collectively, “Feedback”) submitted to the Company become the
                    property of the Company. Company is under no obligation to keep
                    feedback confidential, is not responsible for any ideas
                    (including but not limited to any product, application, website,
                    service, or promotional idea), and is not responsible for any
                    similarities that may appear in the Services or operations in
                    the future. Without limitation, Company has exclusive ownership
                    of all present and future rights in the Feedback of any kind and
                    nature, anywhere, and is entitled to use the Feedback for any
                    commercial or other purpose without compensation to you or
                    anyone else for the Feedback . You acknowledge that you are
                    responsible for the material you submit and that you, not the
                    company, bear full responsibility for the message, including its
                    legality, reliability, adequacy, originality and copyright. You
                    agree that any testimony you give regarding the Services
                    represents your honest opinion and is provided without
                    compensation.
        </p> <p>
        COPYRIGHT<br/>You acknowledge that all materials relating to
                    the Services, including the design, graphics, text, sounds,
                    images, software, and other files of the Services, and their
                    selection and arrangement (collectively, the “Materials”), are
                    owned by the Company and / or are owned by the Company and / or
                    the Licensors are subject to and are protected by international
                    copyright law and other laws and intellectual property rights.
                    You do not acquire any ownership interest in the materials or
                    services by this agreement or otherwise.<br/>All rights to
                    materials not expressly granted in this agreement are reserved
                    by their respective copyright owners. Except as expressly
                    authorized by this Agreement or the Services, you may not copy,
                    reproduce, distribute, republish, download, perform, display,
                    publish, transmit, use, create derivative works from, or
                    otherwise use any materials in any form or by any means on in
                    any event without the prior written consent of the company or
                    the respective copyright holder.<br/>The company authorizes you
                    to view and download the materials for your personal,
                    non-commercial use only, provided you keep intact all copyright
                    and other proprietary notices contained in the original
                    materials. You may not modify, use or adapt the materials in any
                    way, or otherwise use them for any public or commercial purpose.
                    The trademarks, service marks, trade names, trademarks and logos
                    (collectively, “Marks”) contained or described in the Services
                    are the sole property of the Company and / or its licensors and
                    may not be copied, imitated, or otherwise used in whole or in
                    part without prior written permission of the company and / or
                    the licensor. In addition, all page headers, custom graphics,
                    button icons, and scripts are trademarks of the Company and may
                    not be copied, emulated, or otherwise used in whole or in part
                    without the Company’s prior written consent.<br/>The company
                    can enforce its intellectual property rights to the fullest
                    extent of the law.
        </p> <p>
        THIRD PARTY LINKS<br/>Links in the Services to third party
                    websites and applications may be provided for your convenience.
                    If you use these links, you will leave the services. Your
                    dealings with third parties via links to such third party
                    websites or applications are exclusively between you and these
                    third parties. You agree that the company and its related
                    parties (as defined) will not be responsible or liable for any
                    content, goods or services provided on or through these external
                    websites or applications, or for your use of or inability to
                    access such websites or to use applications. You use these links
                    at your own risk. You are cautioned that other websites on the
                    Internet and mobile applications, including third party websites
                    and applications linked through the Services, may contain
                    material or information that some individuals may find offensive
                    or inappropriate. or that is inaccurate, untrue, misleading or
                    misleading; or that is defamatory, slanderous, violates the
                    rights of others or is otherwise unlawful. The company expressly
                    disclaims all responsibility for the content, legality,
                    propriety or correctness of information, as well as for products
                    and services that appear on third party websites or
                    applications.<br/>Without limiting the foregoing, your
                    correspondence or business relationships with, participation in
                    promotions or purchases from advertisers or third party
                    applications found on or through your use of the Services,
                    including payment for and delivery of related goods or services
                    and other terms, conditions, warranties or representations
                    relating to such dealings are solely between you and this
                    advertiser or third party application provider. You agree that
                    the Company and its related parties (as defined) will not be
                    responsible or liable for any loss or damage of any kind arising
                    out of such dealings or the presence of such advertisers or
                    third party application providers on the Services.<br/>The
                    Company is not responsible for any products or services
                    (including third party applications) sold on or through the
                    Services, or for any quality or performance claims made on or
                    about the Services.
        </p> </div> </div>
        <Footer/>
   </>
  )
}

export default Terms